// some hipster words are hard to understand for
// the syllable counter. so we push those in here instead.
export const DICTIONARY = {
  "+1": 2,
  "3": 1,
  "8-bit": 2,
  "90's": 2,
  "cold-pressed": 2,
  "pbr&b": 5,
  axe: 1,
  beer: 1,
  chicharrones: 4,
  cliche: 2,
  diy: 3,
  iceland: 2,
  intelligentsia: 5,
  mlkshk: 2,
  scenester: 2,
  shoreditch: 2,
  tbh: 3,
  tousled: 2,
  typewriter: 3,
  xoxo: 4,
};
